/**
 * Entrypoint for production. This will import the public path from where to laod chunks/assets from
 * ./public path.
 */
import "./publicPath";

const moduleLoading = import("./main");

const StagePlayer = (...args) => {
  moduleLoading.then(module => {
    module.StagePlayer(...args);
  });
};

export { StagePlayer };
